import { Box, SxProps, Typography } from "@mui/material";

export const hellbendersDescriptionWrapper: SxProps = {
  width: "100%",
  margin: "1.5vmax 0",
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  alignItems: "center",
  textAlign: "center",
};

const HellbendersDescription = () => (
  <Box sx={hellbendersDescriptionWrapper}>
    <Typography
      variant="h3"
      sx={{ maxWidth: "60%" }}
    >{`We’re an outlaw biker gang of amphibious superheroes on an epic adventure beyond time, space and the death-grip of global civilization.`}</Typography>
  </Box>
);

export default HellbendersDescription;
